
import React from 'react';
import HighlightBanner from '../components/HighlightBanner';
import ListingContainer from '../components/ListingContainer';
import "../styles/css/bucketpage.css";

function Listing(props) {
    let listingData = props.data;
    return (
        <>
            <main role="main" aria-label="main content">
                <div className="container wrapper listing-wrapper">
                    <HighlightBanner data={listingData} />
                    <ListingContainer data={listingData} title={props.title} />
                </div>
            </main>
        </>
    )
}

export default Listing;