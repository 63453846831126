import React, { useEffect } from 'react';
import parse from "html-react-parser";
import Tilt from 'react-parallax-tilt';

function HomeContainer(props) {
    let homeData = props.data;

    const fadeAnimation = () => {
        var elementReveals = document.querySelectorAll(".fade-in, .fade-in2, hr");

        for (var i = 0; i < elementReveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = elementReveals[i].getBoundingClientRect().top;
            var elementVisible = 20;
            if (elementTop < windowHeight - elementVisible) {
                elementReveals[i].classList.add("active");
            } else {
                elementReveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", fadeAnimation);

    useEffect(() => {
        var horizonalLine = document.querySelectorAll("hr");
        for (var i = 0; i < horizonalLine.length; i++) {
            horizonalLine[i].classList.add("lineanim");
        }

        fadeAnimation();
    }, []);

    return (
        <>
            {
                homeData ? (
                    homeData.data ? (
                        homeData.data.map(
                            (innerData, index) => (
                                <div className="row homerow" key={index} id="homeScrollToSection">
                                    <div className={(index !== 0) ? `col-xlg-12 aRow` : `col-xlg-12`}>
                                        <div className="w-100 position-relative" id="imageRow2">
                                            <div className="fade-in" style={{ "top": "0px" }} >
                                                <img alt={innerData.image.alt} src={innerData.image.url} title={innerData.image.title} className="float-right img-fluid" id="imgR2" />
                                            </div>
                                            <div className="position-absolute">
                                                <img alt="nbcuniversal" src={innerData.textAlign === 'Left' ? '../../../assets/bluestripe.png' : '../../../assets/bluestripe2.png'} className="img-fluid fade-in2" style={{ "mixBlendMode": "multiply" }} id="bluestripeR4" />
                                            </div>
                                            <Tilt transitionSpeed={1000} tiltMaxAngleX={3} tiltMaxAngleY={7}>
                                                <div className={innerData.textAlign === 'Left' ? 'position-absolute float-left leftHeaders' : 'position-absolute float-right rightHeaders'}>
                                                    <a href={innerData.alias}>
                                                        <div id={`allCopyR${index}`} className="homeallcopy">
                                                            <p id={`copyA${index}`} className="imgcopyaright fade-in" style={{ "textAlign": innerData.textAlign }}>
                                                                <br />
                                                                <span className="textcolor1">
                                                                    {innerData.title.split('\n').flatMap((item, i) => [item, <br />])}
                                                                </span>
                                                            </p>
                                                            <p className="imgcopybright fade-in" id={`copyB${index}`} style={{ "textAlign": innerData.textAlign }}>
                                                                {innerData.subHeading.split('\n').flatMap((item, i) => [item, <br />])}
                                                            </p>
                                                        </div>
                                                    </a>
                                                    <a className={innerData.textAlign === 'Left' ? 'viewIt2 float-left fade-in2' : 'viewIt2 float-right fade-in2'} id="viewBtn2" href={innerData.alias}>
                                                        <img alt="nbcuniversal" src="../../../assets/viewit.svg" width="100%" height="100%" className="img-fluid" style={{ "fontFamily": 'MaisonRegular', "width": "100%", "height": "100%" }} />
                                                    </a>
                                                </div>
                                            </Tilt>
                                        </div>
                                    </div>
                                    {
                                        innerData.homeDescription ? (
                                            <div className="col-xlg-12 w-100" id={`imageRow7`}>
                                                <div className="orangecopy fade-in">{parse(innerData.homeDescription)}</div>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            )
                        )
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }

        </>
    )
}

export default HomeContainer;