import React, { useState, useEffect } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

function CookieConsent() {
    const [showPopup, setShowPopup] = useState(false);
    const [animatePopup, setAnimatePopup] = useState(false);
    const [userCountry, setUserCountry] = useState('');
    const allowedCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB'];

    const styles = {
        popup: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 'auto',
            backgroundColor: '#222',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .5)',
            opacity: '0.95',
            color: '#fff',
            textAlign: 'center',
            zIndex: '99999',
            transition: 'transform 1s ease-in-out',
            transform: 'translateY(100%)',
        },
    };

    // set cookies
    const setCookie = () => {
        Cookies.set("cookie-agreed", "2", { expires: 100, sameSite: 'Strict' });
        Cookies.set("cookie-agreed-version", "1.0.0", { expires: 100, sameSite: 'Strict' });
    };

    const OptanonWrapper = () => {
         setUserCountry(window.OneTrust.getGeolocationData().country);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
               OptanonWrapper();
               checkPopupConditions();
          }, 1000);

        // Check if the consent is already given
        const checkPopupConditions = async () => {
            const consent = Cookies.get('cookie-agreed');
            const isCountryInList = allowedCountries.includes(userCountry);

            if (!consent && isCountryInList) {
                setShowPopup(true);
                setTimeout(() => {
                    setAnimatePopup(true);
                }, 100);
            }
        }
        return () => clearTimeout(timer);
    }, [allowedCountries, userCountry]);

    // onclick understand set cookie and close popup
    const handleUnderstand = () => {
        setCookie();
        setAnimatePopup(false);
        setTimeout(() => {
            setShowPopup(false);
        }, 500);
    };

    // Don't render the popup if it's hidden
    if (!showPopup) return null;

    return (
        <div id="sliding-popup" style={{ ...styles.popup, transform: animatePopup ? 'translateY(0)' : 'translateY(100%)' }}>
            <div aria-labelledby="popup-text"
                className="eu-cookie-compliance-banner eu-cookie-compliance-banner-info eu-cookie-compliance-banner--default"
                aria-hidden="false">
                <div className="popup-content info eu-cookie-compliance-content">
                    <div id="popup-text" className="eu-cookie-compliance-message" role="document">
                        <div className="BubbleStyle_MessagesContainer">
                            <div className="BubbleStyle_MessageContainer" id="msg_id_1547">
                                <div><span>We and our partners use cookies on this site to improve our service, perform
                                    analytics, personalize advertising, measure advertising performance, and remember
                                    website preferences.&nbsp;&nbsp;By using the site, you consent to these cookies. For
                                    more information on cookies including how to manage your consent visit our <a
                                        href="https://www.nbcuniversal.com/privacy/cookies" tabIndex="0">Cookie
                                        Policy.</a></span></div>
                            </div>
                        </div>
                    </div>
                    <div id="popup-buttons" className="eu-cookie-compliance-buttons" onClick={handleUnderstand}>
                        <button type="button" className="agree-button eu-cookie-compliance-default-button"
                            tabIndex="0">Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieConsent;
