import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PageTitle({ slugValue, descriptionValue, canonicalValue }) {
    const pageTitleValue = slugValue?.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    const pageDescription = descriptionValue;
    const canonicalURL = canonicalValue;
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{pageTitleValue}</title>
                    <meta property="og:title" content={pageTitleValue} />
                    <meta name="description" content={pageDescription} />
                    <meta name="og:description" content={pageDescription} />
                    <link rel="canonical" href={canonicalURL} />
                </Helmet>
            </HelmetProvider></>
    )
}

export default PageTitle;