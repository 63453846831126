import React, { useState, useEffect } from "react";
import Home from "../pages/Home";
import Listing from "../pages/Listing";
import Details from "../pages/Details";
import PageNotFound from "../pages/PageNotFound";
import axios from "axios";

function Content(props) {
  const apiSiteURL = process.env.REACT_APP_CMS_API;
  const alias = window.location.pathname;
  const [contentData, setContentData] = useState(null);
  const [status, setStatus] = useState(null);
  const api_url = apiSiteURL + `/content?_format=json&alias=`;
  const type = '';

  useEffect(() => {
    const getContentData = async () => {
      try {
        const res = await axios.get(api_url + ((alias === '/') ? 'listing_page' : alias));
        if (typeof res?.data === "object") {
          setContentData(res.data);
          setStatus(res.data.status);
        } else {
          setContentData(null);
          setStatus(res.data.status);
        }
        return res;
      } catch (error) {
        if (error.response) {
          setStatus(400);
        }
      }
    };
    getContentData();
  }, [api_url, alias]);

  if (status === 400) {
    return (
      <PageNotFound data={contentData} title={props.title} />
    )
  } else if (status === 200) {
    const type = contentData.type;
    // const type = contentData?.data.type;
    const title = contentData?.data?.title + " | NBCUniversal creative"
    switch (type) {
      case 'home_page':
        return (
          <Home data={contentData} title={props.title} />
        )
      case 'listing_page':
        return (
          <Listing data={contentData} title={props.title} />
        )
      case 'page':
        return (
          <Details data={contentData} title={props.title} />
        )
      default:
        return (
          <PageNotFound data={contentData} title={props.title} />
        )
    }
  }
}

export default Content;