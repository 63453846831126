import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/css/bootstrap_cust.css';
import './styles/css/LandscapeMessage.css';
// import './styles/css/bucketpage.css';
import './styles/css/homepage.css';
import './styles/css/verticallogo.css';
import './styles/css/footer.css';
import './styles/css/menustyle.css';
import './styles/css/cssText.css';
import './styles/css/overlayRollover.css';
import './styles/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
