import React, { useEffect } from 'react';
import parse from "html-react-parser";
import { Link } from 'react-scroll';
import PageTitle from './PageTitle';

function HighlightText(props) {
    let headerTextData = props.data;

    useEffect(() => {

    }, []);

    return (
        <>
            <PageTitle slugValue={headerTextData?.data?.metatags?.title}
                descriptionValue={headerTextData?.data?.metatags?.description}
                canonicalValue={headerTextData?.data?.metatags?.canonical_url} />
            {
                headerTextData ? (
                    headerTextData.data ? (
                        <div className="row row1" id="row1">
                            {
                                headerTextData.data.header ? (
                                    <div className="col-xlg-12 position-absolute homepagetext" id="HomepageCopy">
                                        {parse(headerTextData.data.header)}
                                    </div>
                                ) : null
                            }
                            {
                                headerTextData.data.header ? (
                                    <div className="mx-auto mob-homepagetext" id="HomepageCopyMobile">
                                        {parse(headerTextData.data.header)}
                                    </div>
                                ) : null
                            }
                            <div className="mx-auto" id="downArrow">
                                <Link to="homeScrollToSection" smooth={false}>
                                    <img id="arrow" className="down-arrow" alt="nbcuniversal" src="../../../assets/arrowdown.svg" />
                                </Link>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default HighlightText;