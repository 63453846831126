import Routing from './router/routing';

function App() {
  return (
    <>
    <Routing />
  </>
  );
}

export default App;