
import React from 'react';
import HighlightGrid from '../components/HighlightGrid';
import DetailsContainer from '../components/DetailsContainer';
import RelatedProject from '../components/RelatedProject';
import "../styles/css/detailPage.css";

function Details(props) {
    let detailsData = props.data;

    return (
        <>
            <main role="main" aria-label="main content">
                <div className="container wrapper details-wrapper">
                    <HighlightGrid data={detailsData} />
                    <DetailsContainer data={detailsData} />
                    <RelatedProject data={detailsData} />
                </div>
            </main>
        </>
    )
}

export default Details;