import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Content from "../pages/Content";
import Login from "../pages/Login";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Content title="Home | NBCUniversal creative" />} />
          <Route path="*" element={<Content />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="user" element={<Login />} />
        <Route path="user/login" element={<Login />} />
        <Route path="admin" element={<Login />} />
      </Routes>
    </BrowserRouter>

  );
}

export default Routing;
