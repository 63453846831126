
import React, { useEffect } from 'react';
import HighlightText from '../components/HighlightText';
import HomeContainer from '../components/HomeContainer';
import ConfigAPI from "../config/config";
import "../styles/css/homepage.css";

function Home(props) {
    let headerTextData = ConfigAPI('heder_footer');

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    return (
        <>
            <main role="main" aria-label="main content">
                <div className="container wrapper home-wrapper">
                    <HighlightText data={headerTextData} />
                    <HomeContainer data={props.data} />
                </div>
            </main>
        </>
    )
}

export default Home;