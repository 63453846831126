import React from 'react';
import parse from "html-react-parser";

function HighlightGrid(props) {
    let headerGridData = props.data;

    return (
        <>
            {
                headerGridData ? (
                    headerGridData.data ? (
                        <div className="container overflow-hidden" id="pinHeader">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-10 w-100" id="pin">
                                    <div className="position-relative" id="titleRow0">
                                        <p id="detailTitle" className="headerTitle">
                                            <br />
                                            {headerGridData?.data?.title}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                            </div>
                            <div className="row detailCopy">
                                <div className="col-lg-1"></div>
                                {
                                    headerGridData?.data?.description ? (
                                        <div className="col-lg-4 subheader" id="sh1">
                                            {parse(headerGridData?.data?.description)}
                                        </div>
                                    ) : null
                                }
                                {
                                    headerGridData?.data?.partners ? (
                                        <div className="col-lg-3 subheader" id="sh2">
                                            <span className="SubheadLabel">PARTNERS</span>
                                            <br />
                                            {parse(headerGridData?.data?.partners)}
                                        </div>
                                    ) : null
                                }
                                {
                                    headerGridData?.data?.assetsCreated ? (
                                        <div className="col-lg-3 subheader" id="sh3">
                                            <span className="SubheadLabel">ASSETS CREATED</span>
                                            <br />
                                            {parse(headerGridData?.data?.assetsCreated)}
                                        </div>
                                    ) : null
                                }
                                <div className="col-lg-1"></div>
                            </div>
                        </div>
                    ) : (
                        null
                    )

                ) : (
                    null
                )
            }
        </>
    )
}

export default HighlightGrid;