import React from 'react';

function HighlightBanner(props) {
    let bannerData = props.data;

    return (
        <>
            {
                bannerData ? (
                    bannerData.data ? (
                        <div className="row">
                            <div className="col-xlg-12">
                                <div className="w-100 position-relative" id="imageRow0">
                                    {bannerData.data?.image?.url ? (
                                        <div style={{ "top": "0px" }} className="w-100 position-relative"> <img alt="nbcuniversal" src={bannerData.data.image.url} className="float-left img-fluid" />
                                        </div>
                                    ) : (
                                        <div style={{ "top": "0px" }} className="w-100 position-relative"> <img alt="nbcuniversal" src="../../../assets/img5.png" className="float-left img-fluid" />
                                        </div>
                                    )}
                                    {bannerData.data?.title || bannerData.data?.subHeading ? (
                                        <div className={bannerData.data.textAlign === 'Left' ? "position-absolute float-left leftHeaders" : "position-absolute float-right rightHeaders"}>
                                            <div>
                                                {bannerData.data?.title ? (
                                                    <p className={bannerData.data.textAlign === 'Left' ? "imgcopya " : "imgcopyaright "}>
                                                        <br /><span>{bannerData.data?.title.split('\n').flatMap((item, i) => [item, <br />])}</span>
                                                    </p>
                                                ) : (
                                                    null
                                                )}
                                                {bannerData.data?.subHeading ? (
                                                    <p className={bannerData.data.textAlign === 'Left' ? " imgcopyb" : " imgcopybright"} id="copyB4">
                                                        {bannerData.data?.subHeading.split('\n').flatMap((item, i) => [item, <br />])}
                                                    </p>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default HighlightBanner;