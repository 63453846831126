import React, { useEffect } from 'react';
import PageTitle from './PageTitle';
import Tilt from 'react-parallax-tilt';

function ListingContainer(props) {
    let listingData = props.data;

    const fadeAnimation = () => {
        var elementReveals = document.querySelectorAll(".fade-in3");

        for (var i = 0; i < elementReveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = elementReveals[i].getBoundingClientRect().top;
            var elementVisible = 20;
            if (elementTop < windowHeight - elementVisible) {
                elementReveals[i].classList.add("active");
            } else {
                elementReveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", fadeAnimation);

    useEffect(() => {
        if (window.innerWidth < 1200) {
            fadeAnimation();
        }
    }, []);

    return (
        <>
            <PageTitle slugValue={listingData?.data?.metatags?.title}
                descriptionValue={listingData?.data?.metatags?.description}
                canonicalValue={listingData?.data?.metatags?.canonical_url} />
            {
                listingData ? (
                    listingData.data ? (
                        listingData.data?.contents ? (
                            listingData?.data?.contents.map((sec, index) => {
                                return (
                                    <div className={(index === 0) ? "row photoRow" : "row photoRowBottom"} key={index}>
                                        <div className="col-lg-1"></div>
                                        {sec.map((innerData, index2) => {
                                            return (
                                                <div className="col-lg-5 img-fluid fade-in3 imgcol overflow-hidden topImg listing-col" key={index2}>
                                                    <Tilt transitionSpeed={1000} tiltMaxAngleX={0} tiltMaxAngleY={16}>
                                                        <img alt={innerData?.image?.alt} title={innerData?.image?.title} src={innerData?.image?.url} className="img-fluid photo" />
                                                        <a href={innerData?.alias}>
                                                            <div className="overlayParent" id={index2}>
                                                                <div className="bucketoverlay"></div>
                                                                <p className="imgTitles listing-imgtitles">{innerData?.title}</p>
                                                            </div>
                                                        </a>
                                                        <a href={innerData?.alias}>
                                                            <div className="mobileTitles">
                                                                <p style={{ "color": "#FC5A16 !important" }}>{innerData?.title}</p>
                                                            </div>
                                                        </a>
                                                    </Tilt>
                                                </div>
                                            )
                                        })}
                                        <div className="col-lg-1"></div>
                                    </div>
                                )
                            })
                        ) : (
                            null
                        )
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default ListingContainer;