import React, { useEffect, useRef } from 'react';
import { Outlet } from "react-router-dom";
import { gsap } from "gsap";
import { Power4, Quad } from "gsap/all";
import { Link } from 'react-router-dom';

function Header(props) {
    let menuData = props.data;
    var buttonPower = true;
    const menuBtnRef = useRef(null);
    const openMenuRef = useRef(null);
    const closeWrapperRef = useRef(null);
    const verticallogoRef = useRef(null);
    const lineRefs = useRef([]);
    const textRefs = useRef([]);
    const lineRef1 = useRef(null);
    const lineRef2 = useRef(null);
    const lineRef3 = useRef(null);
    const topbarRef = useRef(null);

    // Menu open function
    const openMenuFunc = () => {
        setButtonPower(false, false);
        openMenuRef.current.style.display = 'block';
        menuBtnRef.current.style.display = 'none';
        closeWrapperRef.current.style.display = 'block';
        closeWrapperRef.current.style.opacity = 1;
        var buttonHeight = closeWrapperRef.current.clientHeight;
        gsap.to(verticallogoRef.current, 0.1, { opacity: 0, onComplete: function () { verticallogoRef.current.style.display = 'none'; } }, "-=0");
        closeButtonAnimation(buttonHeight);
        menuAnimBuild();
    }

    // Menu close function
    function closeMenuFunc() {
        var buttonHeight = closeWrapperRef.current.clientHeight;
        if (openMenuRef.current.style.display === 'block') {
            setButtonPower(false, false);
            gsap.to(verticallogoRef.current, 0.1, { opacity: 1, onStart: function () { verticallogoRef.current.style.display = 'block'; } }, "-=1");
            resetCloseBtn(buttonHeight);
            menuAnimDeBuild();
        }
    }

    function setButtonPower(myval, closeDisplay) {
        buttonPower = myval;
        if (closeDisplay) { closeWrapperRef.current.style.display = "none" }
    }

    // menu animation build
    function menuAnimBuild() {
        gsap.timeline({ onStart: onMenuBuildStart });
        for (let i = 0; i < menuData[0]?.menu?.length; i++) {
            gsap.fromTo(lineRefs.current[i], .25, { y: "+=245", x: -1500, delay: 0, opacity: 1, duration: 0, ease: Power4.easeOut, transformOrigin: "50% 100%" },
                { y: "-=245", x: 0, ease: Power4.easeOut, transformOrigin: "50% 100%" }, "-=.15")
            gsap.to(textRefs.current[i], { opacity: 1, delay: 0, duration: 0, ease: Quad.easeOut }, "-=.1")
            if (menuData[0]?.menu?.length - 1) {
                gsap.to(textRefs.current[i], { opacity: 1, delay: 0, duration: 0, onComplete: setButtonPower, onCompleteParams: [true, false], ease: Quad.easeOut }, "-=.1")
            }
        }

    }

    // menu animation debuild
    function menuAnimDeBuild() {
        gsap.timeline({ onStart: onMenuDeBuildStart });
        for (let i = 0; i < menuData[0]?.menu?.length; i++) {
            gsap.to(lineRefs.current[i], .25, { x: -1500, delay: 0, ease: Power4.easeOut, transformOrigin: "50% 100%" }, "-=.15")
            gsap.to(textRefs.current[i], .15, { opacity: 0, delay: 0, ease: Quad.easeOut }, "-=.2")
            if (menuData[0]?.menu?.length - 1) {
                gsap.to(textRefs.current[i], .12, { opacity: 0, delay: 0, onComplete: resetStripesDeBuild, ease: Quad.easeOut }, "-=.2")
            }
        }
    }

    function onMenuBuildStart() {
        for (let i = 0; i < menuData[0]?.menu?.length; i++) {
            lineRefs.current[i].style.opacity = "1";
            textRefs.current[i].style.opacity = "0";
        }
    }

    // reset menu stripes animation
    function resetStripesDeBuild() {
        setButtonPower(true, false);
        for (let i = 0; i < menuData[0]?.menu?.length; i++) {
            lineRefs.current[i].style.opacity = "0";
            textRefs.current[i].style.opacity = "0";
        }
        openMenuRef.current.style.display = 'none';
    }

    function onMenuDeBuildStart() {
        setButtonPower(false, false);
    }

    // close button animation
    function closeButtonAnimation(btnVal) {
        gsap.timeline();
        if (btnVal > 40) {
            gsap.to(lineRef2.current, .25, { opacity: 0, delay: 0, ease: Quad.easeOut }, "-=0")
            gsap.to(lineRef1.current, .25, { y: "+=10", delay: 0.5, ease: Quad.easeOut }, "-=0")
            gsap.to(lineRef3.current, .25, { y: "-=10", delay: 0.5, ease: Quad.easeOut }, "-=.25")
            gsap.to([lineRef1.current], .25, { rotation: -40, delay: 0.5, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([lineRef3.current], .25, { rotation: 40, delay: 0.5, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=.25")
        } else {
            gsap.to(lineRef2.current, .25, { opacity: 0, delay: 0, ease: Quad.easeOut }, "-=0")
            gsap.to(lineRef1.current, .25, { y: "+=5", delay: 0.5, ease: Quad.easeOut }, "-=0")
            gsap.to(lineRef3.current, .25, { y: "-=5", delay: 0.5, ease: Quad.easeOut }, "-=.25")
            gsap.to([lineRef1.current], .25, { rotation: -50, delay: 0.5, ease: Quad.easeOut, transformOrigin: '40% center' }, "-=0")
            gsap.to([lineRef3.current], .25, { rotation: 50, delay: 0.5, ease: Quad.easeOut, transformOrigin: '40% center' }, "-=.25")
        }
    }

    function setMenuBtnBlock() {
        menuBtnRef.current.style.display = "block";
    }

    // reset close button animation
    function resetCloseBtn(btnVal) {
        if (btnVal > 40) {
            gsap.timeline();
            gsap.to([lineRef3.current, lineRef1.current], .25, { rotation: 0, delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([lineRef1.current], .25, { y: "-=10", delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([lineRef3.current], .25, { y: "+=10", delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=.25")
            gsap.to([lineRef2.current], .25, { onComplete: setMenuBtnBlock, opacity: 1, delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([closeWrapperRef.current], .25, { onComplete: setButtonPower, onCompleteParams: [true, true], opacity: 0, delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
        } else {
            gsap.timeline();
            gsap.to([lineRef3.current, lineRef1.current], .25, { rotation: 0, delay: 0, ease: Quad.easeOut, transformOrigin: '40% center' }, "-=0")
            gsap.to([lineRef1.current], .25, { y: "-=5", delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([lineRef3.current], .25, { y: "+=5", delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=.25")
            gsap.to([lineRef2.current], .25, { onComplete: setMenuBtnBlock, opacity: 1, delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
            gsap.to([closeWrapperRef.current], 0, { onComplete: setButtonPower, onCompleteParams: [true, true], opacity: 0, delay: 0, ease: Quad.easeOut, transformOrigin: 'center center' }, "-=0")
        }
    }



    useEffect(() => {
        gsap.to(menuBtnRef.current, { opacity: 1, delay: 1, ease: Quad.easeOut });
        gsap.to(verticallogoRef.current, { opacity: 1, delay: 1, ease: Quad.easeOut });
        gsap.to(topbarRef.current, { delay: 1, y: 0 });

    }, []);

    return (
        <>
            <header className="header" role="navigation" aria-label="main navigation">
                <div className="container header-container">
                    <div className="" id="LandscapeMessage" >
                        <span> Please view in portrait mode.</span>
                    </div>
                </div>
                <div className="container fixed-top">
                    <div className="row" id="topbar" ref={topbarRef}></div>
                    <div className="row row0" id="row0">
                        <div className="col-xlg-12 w100" >
                            <div id="verticallogo" ref={verticallogoRef}>
                                <Link to="/" className="main-logo">
                                    <img alt="nbcuniversal" className="default-logo" src="../../../assets/verticallogo.svg" height="200" id="logo" />
                                    <img alt="nbcuniversal" className="hover-logo" src="../../../assets/verticallogoorange.svg" height="200" id="logo" />
                                    <img alt="nbcuniversal" src="../../../assets/inspired.svg" width="65%" id="logoAnim" />
                                </Link>
                            </div>
                            <div className="navwrapper2" ref={closeWrapperRef} id="closeWrapper" onClick={closeMenuFunc}>
                                <div className="circle2">
                                    <div className="nav2">
                                        <div ref={lineRef1} className="one2" id="LINE1"></div>
                                        <div ref={lineRef2} className="two2" id="LINE2"></div>
                                        <div ref={lineRef3} className="three2" id="LINE3"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="navwrapper" ref={menuBtnRef} id="menuBtn" onClick={openMenuFunc}>
                                <div className="circle">
                                    <div className="nav">
                                        <div className="one"></div>
                                        <div className="two"></div>
                                        <div className="three"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
            {/* menu section */}
            {
                menuData ? (
                    menuData[0].menu ? (
                        <div ref={openMenuRef} className="container fixed-top" id="openmenu">
                            <div className="row">
                                <div className="col-sm-12 overflow-hidden menu-row-first">
                                    <div className="mw-100 overflow-hidden menu-row-second">
                                        {menuData[0].menu && menuData[0].menu.map((data, key) => (
                                            <div ref={(el) => (lineRefs.current[key] = el)} className={`mh-100 position-absolute parallelogram ${data.alias}`} id={`mStripe${key}`} key={key}></div>
                                        ))}
                                        <div className="mw-100 menu-types" id="menutypes">
                                            {menuData[0].menu && menuData[0].menu.map((data, key) => (
                                                <a ref={(el) => (textRefs.current[key] = el)} className="menucopy mw-100" id={`mc${key}`} href={`/` + data.alias} key={key}>{data.title}</a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )

                ) : (
                    null
                )
            }
            <Outlet />
        </>
    )
}

export default Header;