import React from 'react';
import Tilt from 'react-parallax-tilt';

function RelatedProject(props) {
    let detailsData = props.data;

    const fadeAnimation = () => {
        var elementReveals = document.querySelectorAll(".fade-in");

        for (var i = 0; i < elementReveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = elementReveals[i].getBoundingClientRect().top;
            var elementVisible = 20;
            if (elementTop < windowHeight - elementVisible) {
                elementReveals[i].classList.add("active");
            } else {
                elementReveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", fadeAnimation);

    return (
        <>
            {
                detailsData ? (
                    detailsData.data ? (
                        (detailsData.data?.related?.prev || detailsData.data?.related?.next) ? (
                            <div>
                                <div className="row">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-10">
                                        <hr />
                                    </div>
                                    <div className="col-lg-1" ></div>
                                </div>

                                <div className="row row0_detail">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                        <p className="relatedText">
                                            <a href={detailsData?.data?.related?.prev?.alias}>
                                                &#8592; Previous
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                        <p className="relatedText2">
                                            <a href={detailsData?.data?.related?.next?.alias}>

                                                Next &#8594;
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                        <p className="relatedText3">
                                            <a href={detailsData?.data?.returnto?.alias}>
                                                Return to {detailsData?.data?.returnto?.title} &#8617;
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <div className="row relatedphotoRow fade-in">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-3 img-fluid imgcol related-listing-col">
                                        <Tilt transitionSpeed={1000} tiltMaxAngleX={0} tiltMaxAngleY={16}>
                                            <img alt={detailsData?.data?.related?.prev?.image?.alt} title={detailsData?.data?.related?.prev?.image?.title} src={detailsData?.data?.related?.prev?.image?.url} className="img-fluid photo" />
                                            <a href={detailsData?.data?.related?.prev?.alias}>
                                                <div className="overlayParent overlayPrevNext">
                                                    <div className="overlay relatedoverlay"></div>
                                                    <p className="imgTitles related-imgtitles">{detailsData?.data?.related?.prev?.title}</p>
                                                </div>
                                            </a>
                                            <div className="mobileTitles">
                                                <p>
                                                    <br />{detailsData?.data?.related?.prev?.title}
                                                </p>
                                            </div>
                                        </Tilt>
                                    </div>
                                    <div className="col-lg-3 img-fluid imgcol related-listing-col">
                                        <Tilt transitionSpeed={1000} tiltMaxAngleX={0} tiltMaxAngleY={16}>
                                            <img alt={detailsData?.data?.related?.prev?.image?.alt} title={detailsData?.data?.related?.next?.image?.title} src={detailsData?.data?.related?.next?.image?.url} className="img-fluid photo" />
                                            <a href={detailsData?.data?.related?.next?.alias}>
                                                <div className="overlayParent overlayPrevNext">
                                                    <div className="overlay relatedoverlay"></div>
                                                    <p className="imgTitles related-imgtitles">{detailsData?.data?.related?.next?.title}</p>
                                                </div>
                                            </a>
                                            <div className="mobileTitles">
                                                <p>
                                                    <br />{detailsData?.data?.related?.next?.title}
                                                </p>
                                            </div>
                                        </Tilt>
                                    </div>
                                </div>

                                <div className="col-lg-1"></div>
                            </div>
                        ) : (
                            null
                        )
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default RelatedProject;