import React, { useEffect } from 'react';
import parse from "html-react-parser";
import PageTitle from './PageTitle';

function DetailsContainer(props) {
    let detailsData = props.data;

    const fadeAnimation = () => {
        var elementReveals = document.querySelectorAll(".fade-in");

        for (var i = 0; i < elementReveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = elementReveals[i].getBoundingClientRect().top;
            var elementVisible = 20;
            if (elementTop < windowHeight - elementVisible) {
                elementReveals[i].classList.add("active");
            } else {
                elementReveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", fadeAnimation);

    useEffect(() => {
        fadeAnimation();
    }, []);

    return (
        <>
            <PageTitle slugValue={detailsData?.data?.metatags?.title}
                descriptionValue={detailsData?.data?.metatags?.description}
                canonicalValue={detailsData?.data?.metatags?.canonical_url} />
            {
                detailsData ? (
                    detailsData.data ? (
                        detailsData?.data && detailsData?.data?.detailDescription.map((detailDesc, index) => (
                            <div className="container" id="photoContainer" key={index}>
                                {detailDesc?.type === "Video" && detailDesc?.Video && detailDesc.Video.map((videoData, index1) => (
                                    <div className="row photoRow0_details" key={index1}>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10 img-fluid overflow-hidden fade-in embed-video-container">
                                            <iframe src={videoData.url} title="Video" width="100%"></iframe>
                                        </div>
                                        <div className="col-lg-1"></div>
                                    </div>
                                ))}

                                {detailDesc?.type === "Description" && detailDesc?.Description && detailDesc.Description.map((desData, index3) => (
                                    <div key={index3}>
                                        <div className="row">
                                            <div className="col-lg-1">
                                            </div>
                                            <div className="col-lg-10">
                                                <div>
                                                    <hr className="SubsectionRule" />
                                                    <p className="SubsectionTitle">
                                                        {desData?.sectionTitle}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-1">
                                            </div>
                                            <div className="col-lg-5">
                                                <div>
                                                    {
                                                        desData?.sectionDescription ? (
                                                            <p className="SubsectionDescription">
                                                                {parse(desData?.sectionDescription)}
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {detailDesc?.type === "Image" && detailDesc?.Image && detailDesc.Image.map((imageData, index4) => (
                                    <div className="row photoRow0_details" key={index4}>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10 img-fluid  overflow-hidden details_photo fade-in">
                                            <img alt={imageData?.alt} title={imageData?.title} src={imageData?.url} className="img-fluid" />
                                        </div>
                                        <div className="col-lg-1"></div>
                                    </div>
                                ))}
                            </div>

                        ))
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default DetailsContainer;