import { useEffect, useState } from "react";
import axios from 'axios';

// common API call for all REST API's based on param
const ConfigAPI = function (param) {
  const [responseData, setAPIData] = useState([]);
  const [error, setError] = useState(null);
  let api_url = '';
  const apiSiteURL = process.env.REACT_APP_CMS_API;
  switch (param) {
    case 'heder_footer':
      api_url = apiSiteURL + `/block/1?_format=json`
      break;
    default:
      break;
  }
  useEffect(() => {
    if (api_url) {
      axios(api_url)
        .then((response) => {
          setError(null);
          setAPIData(response.data);
        })
        .catch(setError);
    }
  }, []);
  return responseData;
}
export default ConfigAPI;